<template>
  <v-col
    cols="12"
    sm="6"
    md="6"
    lg="4"
  >
    <div
      @click="dialog = true"
    >
      <base-material-stats-card
        class="toggle-item"
        :color="idx === 0 ? 'success' : idx === 1 ? 'amber darken-3' : 'light-blue darken-1'"
        :icon="currency.symbol === '$' ? 'mdi-currency-usd' : currency.symbol === '€' ? 'mdi-currency-eur' : currency.symbol"
        :title="currency.name"
        :value="'$1 = ' + currency.symbol + currency.value"
      />
    </div>
    <edit-currency-modal
      :dialog="dialog"
      :currency="currency"
      @close="dialog = false"
    />
  </v-col>
</template>

<script>
  import EditCurrencyModal from './EditCurrencyModal.vue'
  export default {
    name: 'Currencies',

    components: {
      EditCurrencyModal,
    },

    props: {
      currency: {
        type: Object,
        default: () => {},
      },
      idx: {
        type: Number,
        default: () => Number,
      },
    },

    data () {
      return {
        dialog: false,
      }
    },

    methods: {
      open () {
        this.dialog = true
      },
      close () {
        this.dialog = false
      },
    },
  }
</script>

<style scoped>
.toggle-item {
  transform: scale(1);
  transition: 200ms all ease;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select:none;
}

.toggle-item:hover {
  transform: scale(1.05);
}

.toggle-item:active {
  transform: scale(1);
}
</style>
