<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <currencies
        v-for="(currency, i) in currencies"
        :key="i"
        :currency="currency"
        :idx="i"
      />
    </v-row>
  </v-container>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import pageCondicionerMixin from '@/mixins/pageCondicionerMixin'
  import { SUPER_ADMIN, ADMIN, GENERAL_MANAGER, STORE_MANAGER } from '@/const'
  import Currencies from '../components/admin-currencies/Currencies.vue'

  export default {
    name: 'AdminCurrenciesView',

    components: {
      Currencies,
    },

    mixins: [pageCondicionerMixin([SUPER_ADMIN, ADMIN, GENERAL_MANAGER, STORE_MANAGER])],

    computed: {
      ...mapState(['currencies', 'fetchingCurrencies', 'actionsBusiness', 'actionsParentBusiness', 'user']),
    },

    async created () {
      await this.getCurrencies({ id: this.user.businessId || this.actionsParentBusiness })
    },

    methods: {
      ...mapActions(['getCurrencies']),
    },
  }
</script>
