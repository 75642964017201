<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card class="text-center">
      <v-card-title>
        Editar valor del {{ currency.name }}

        <v-spacer />

        <v-icon
          aria-label="Close"
          @click="close"
        >
          mdi-close
        </v-icon>
      </v-card-title>
      <br><br>

      <v-card-text>
        <v-form
          v-model="isValid"
          @submit="e => update(e)"
        >
          <v-container class="py-0">
            <v-row>
              <v-col
                cols="12"
              >
                <h2 class="grey--text">
                  $1 equivale a
                </h2>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="form.value"
                  :disabled="currency.isReference === 1 "
                  style="font-size: 1.6rem"
                  required
                  outlined
                  :label="currency.name + 's'"
                  :prepend-inner-icon="currency.symbol === '$' ? 'mdi-currency-usd' : currency.symbol === '€' ? 'mdi-currency-eur' : currency.symbol"
                  type="number"
                  min="0"
                  pattern="^[0-9]+"
                />
              </v-col>
              <v-col v-if="currency.isReference">
                <span class="red--text">
                  Al ser una moneda de referencia, no permite su edicion
                </span>
              </v-col>
              <v-col
                cols="12"
                class="mb-5"
              >
                <v-btn
                  color="secondary"
                  class="mr-0"
                  block
                  type="submit"
                  :disabled="currency.isReference === 1 || !isValid"
                  :loading="fetchingCurrencies"
                >
                  Enviar &nbsp; <v-icon>mdi-send</v-icon>
                </v-btn>
              </v-col>
              <v-col class="mb-1" />
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  export default {
    name: 'EditCurrencyModal',

    props: {
      dialog: {
        type: Boolean,
        default: () => false,
      },
      currency: {
        type: Object,
        default: () => {},
      },
    },

    data () {
      return {
        isValid: false,
        form: {},
      }
    },

    computed: {
      ...mapState(['actionsParentBusiness', 'user', 'fetchingCurrencies', 'success']),
    },

    watch: {
      success (v) {
        this.close()
      },
    },

    created () {
      this.form = this.currency
    },

    methods: {
      ...mapActions(['updateCurrencies', 'getCurrencies']),
      close () {
        this.$emit('close')
      },

      async update (e) {
        e.preventDefault()
        // // // // // // // // // // // // // // // // // // // // // // // // // // console.log(this.form)
        await this.updateCurrencies(this.form)
        await this.getCurrencies({ id: this.user.businessId || this.actionsParentBusiness })
      },
    },
  }
</script>
